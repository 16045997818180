


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Raleway";
  /* font-family: "Poppins"; */
}


/* contact form completion response page */
.testimonal-page {

  height: 100vh;
  background-color: #010101;
}
.email-response{
  font-family: "Poppins";
  display: flex;
  align-items: center;
  justify-content: center;
  background: #010101;
  width: 100%;
  z-index: 1;
  flex-direction: column;
  margin: 0 auto;
  height: 100vh;
  /* padding: 50px 32px; */
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
  color: azure;
}


.contactIcon:hover{
  fill: #9CAF88;

}




.carousel-status{
  display: none;
 
 /* height: 0px; */
 /* z-index: -2; */
}

button.control-arrow{
  /* padding-top: 30px; */
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 70px;
  /* height:200px; */
  /* display: grid; */
  border-radius: 50px;


  
}

a{
  font-family: "Poppins";
  font-size: 14px;

}