@font-face {
  font-family: 'Raleway';
  src: local("Raleway");
  src: url('./fonts/raleway/Raleway-Regular.ttf')format('embedded-opentype');
  src: url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
}
@font-face {
  font-family: "Poppins";
  src: url('./fonts/Poppins/Poppins-Regular.ttf');
}

body {
  font-family: "Raleway", sans-serif;
  background-color: #000;
}

.bg-black-transparent {
  background-color: rgba(0, 0, 0, 0.5);
}

.email{
  color: white;
  text-decoration: none;
}
.email:hover{
  color: #9CAF88;
}
